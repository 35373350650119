import { isValidElement } from 'react';
import styled from '@emotion/styled';

import { getLangText, getLuminance } from '../../helpers/ui';


const Span = styled('span')({
  fontWeight: 600,
  padding: '.2em .6em',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

export default function Label({ color, title, style, className = '' }) {
  return (
    <Span
      className={`label ${className}`}
      style={{
        backgroundColor: color,
        color: getLuminance(color) > 0.5 ? '#000b' : '#fffe',
        ...style
      }}
    >
      {isValidElement(title) ? title : getLangText(title)}
    </Span>
  )
}
