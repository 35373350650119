import wurd from 'wurd-react';
import styled from '@emotion/styled';

import * as helpers from 'helpers';

import LangText from 'components/lang-text';
import StateLabel from 'components/state-label';
import AddonLoader from 'components/loaders/addon';

const cms = wurd.block('website.page.editType.addon');


export function edit({ block, setBlock, modal }) {
  helpers.ui.redirect(`/addons/${block.addonId}`);
}

const AddonPlaceholder = styled.div`
  text-align: center;

  .warning-stripe {
    padding: 1.5rem 1rem;
    background: repeating-linear-gradient(
      -45deg,
      #ffffff,  /* White */
      #ffffff 20px,  /* Changed from 10px to 20px */
      #f6f6f6 20px,  /* Light gray, changed from 10px to 20px */
      #f6f6f6 40px   /* Changed from 20px to 40px */
    );
  }
  
  .logo {
    border: 1px solid #DFDFDF;
    width: 150px;
    max-height: 150px;
    background: #fff;
    object-fit: contain;
    margin-bottom: 1rem;
    padding: 1rem;
  }

  .title {
    margin-bottom: 1rem;
  }

  .state {
    margin-bottom: 1rem;
  }

  .placeholder {
    margin-bottom: 0;
  }
`;


export default function WebsiteBlock_Addon({ block, setBlock, modal }) {
  return (
    <AddonLoader id={block.addonId}>
      {addon => {
        const { addonConfig } = addon;

        return (
          <AddonPlaceholder>
            <div className="warning-stripe">
              <div><img className="logo" src={addonConfig.image} alt="" /></div>
              <h4 className="title"><LangText obj={addonConfig.title} /></h4>

              <div className="state">
                {addon.state === 'paused' && (
                  <StateLabel
                    type="warning"
                  >
                    {wurd.text('addons.paused')}
                  </StateLabel>
                )}
                {addon.state === 'disabled' && (
                  <StateLabel
                    type="danger"
                  >
                    {wurd.text('addons.disabled')}
                  </StateLabel>
                )}
              </div>

              <div className="placeholder">
                {addon.state === 'enabled' && (
                  <cms.Text id="placeholder" />
                )}
                {addon.state !== 'enabled' && (
                  <cms.Markdown id="placeholder_disabled" />
                )}
              </div>
            </div>
          </AddonPlaceholder>
        );
      }}
    </AddonLoader>
  );
}
