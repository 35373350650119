import Modal from 'components/modal';
import SimpleTableList from 'components/simple-table-list'
import AddonConfigListRow from './list-row';


export default function AddonConfigSelector({
  cms,
  modal,
  //site,
  addonConfigs,
  onSelectItem,
}) {
  // Sort the addonConfigs by whether they are installed or not
  addonConfigs = addonConfigs.sort((a, b) => {
    if (a._addon && !b._addon) return 1;
    if (!a._addon && b._addon) return -1;
    return 0;
  });

  return (
    <Modal
      title={<cms.Text id="title" />}
      closeModal={modal.close}
    >
      <SimpleTableList>
        {addonConfigs.map(addonConfig => (
          <AddonConfigListRow
            key={addonConfig.id}
            cms={cms}
            addonConfig={addonConfig}
            addon={addonConfig._addon}
            onClick={() => onSelectItem(addonConfig)}
            style={{ cursor: 'pointer' }}
          />
        ))}
      </SimpleTableList>
    </Modal>
  );
}