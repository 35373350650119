import wurd, { WurdText } from 'wurd-react';

import * as config from 'config';

import TableListRow from 'components/table-list-row';
import LangText from 'components/lang-text';
import StateLabel from 'components/state-label';
import SiteLoader from 'components/loaders/site';


export default function AddonConfigSelectorListItem({
  cms,
  addonConfig,
  addon,
  hideSite,
  ...rest
}) {
  return(
    <TableListRow
      image={addonConfig.image || `${config.publicUrl}/addon.png`}
      title={<LangText obj={addonConfig.title} />}
      subtitle={<LangText obj={addonConfig.subtitle} />}
      extra1={
        <>
          {addon?.state === 'enabled' && (
            <StateLabel
              type="success"
            >
              {wurd.text('addons.enabled')}
            </StateLabel>
          )}
          {addon?.state === 'paused' && (
            <StateLabel
              type="warning"
            >
              {wurd.text('addons.paused')}
            </StateLabel>
          )}
          {addon?.state === 'disabled' && (
            <StateLabel
              type="danger"
            >
              {wurd.text('addons.disabled')}
            </StateLabel>
          )}

          {!hideSite && addon?.siteId && (
            <div>
              <SiteLoader id={addon?.siteId}>
                {(site) => (
                  <StateLabel type="secondary">
                    <LangText>{site.title}</LangText>
                  </StateLabel>
                )}
              </SiteLoader>
            </div>
          )}

          {!hideSite && addon && !addon.siteId && (
            <div>
              <StateLabel type="secondary">
                <WurdText id="common.phrases.allSites" />
              </StateLabel>
            </div>
          )}
        </>
      }
      {...rest}
    />
  );
}
