import axios from 'axios';
import wurd from 'wurd-react';

import * as config from '../config';


// because new URLSearchParams(params) encodes way too many chars, non necessarily
export function paramsToSearch(params) {
  return Object.entries(params)
    .filter(([k, v]) => v != null)
    .map(([k, v]) => `${k}=${encodeURIComponent(v || '')}`)
    .join('&');
}

export function fetchApi(path, { body, method, headers } = {}) {
  return fetch(path.startsWith('/') ? `${config.apiUrl}${path}` : path, {
    method,
    headers: {
      ...headers,
      ...body && { 'Content-Type': 'application/json' },
    },
    body: body && JSON.stringify(body),

  })
    .then(async r => {
      if (!r.ok) {
        const data = await r.json();
        throw Object.assign(new Error(data.error?.message || data.message), { type: data.error?.type });
      }
      return r;
    });
}

export const fetchJson = (url, opts) => fetchApi(url, opts).then(r => r.json());


axios.defaults.withCredentials = true;
axios.defaults.paramsSerializer = paramsToSearch;

//Add base URL
axios.interceptors.request.use(
  function addBaseUrlSuccess(req) {
    if (req.url[0] === '/') {
      req.url = config.apiUrl + req.url;
    }

    return req;
  }
);


//Add Authorization header
// axios.interceptors.request.use(
//   function addAuthHeadersSuccess(req) {
//     if (!req.headers['Authorization']) {
//       var token = localStorage.getItem('accessToken');
//       if (token) {
//         req.headers['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken');
//       }
//     }

//     return req;
//   }
// );

export const getErrMsg = err => wurd.text(`common.errMsg.${err.message.replaceAll(' ' , '_').replace(/[^\w-]/g, '').slice(0, 40)}`, err.errors) || err.message;

//Handle errors; redirect to login on Unauthorized
axios.interceptors.response.use(
  function success(res) {
    //All OK, do nothing
    // console.log('API SUCCESS', res)
    return res;
  },

  /**
   * Deal with %&!# axios
   * @param {Error} err 
   * @param {{
      data: string|{
        error:{
          type: string,
          message: string,
          errors: {[key: string]: {message: string}}
        }
      },
      status: number,
      statusText: string,
      headers: object
    }} [err.response]
   */
  function error(err) {
    if (err.response) {
      if (err.response.data?.error) {
        Object.assign(err, err.response.data.error);
      }
      err.status = err.response.status;
      err.statusText = err.response.statusText;
      err.message = err.message || err.statusText;
      err.message = getErrMsg(err);
    }

    return Promise.reject(err);
  }
);

export default axios;
