import { useEffect } from 'react';
import wurd from 'wurd-react';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';

import * as actions from '../../actions';
import store from '../../store';
import useApiForm from 'hooks/api-form';
import Field from 'components/simple-form-field';
import Markdown, { WurdMarkdown } from 'components/markdown';
import Spinner from 'components/spinner';
import AzureButton from './azure-button';
import GoogleButton from './google-button';
import VerifyEmail from './verify-email';
import ErrorMessage from 'components/error-message';
import Captcha from 'plugins/recaptcha';


const cms = wurd.block('auth.login');

const HideBadge = styled.div({
  '.grecaptcha-badge': { visibility: 'hidden' },
});


export default function LoginForm({ err, onLogin }) {
  const { submit, inputProps, loading, errMsg, err: apiErr } = useApiForm({
    onSubmit: actions.auth.login,
    onSuccess: onLogin,
  });

  const azureUrl = err?.response.headers['x-azure-url'];
  const hashParams = new URLSearchParams(location.hash.replace(/^#\/?/, ''));
  const azureToken = hashParams.get('access_token');
  const googleClientId = err?.response.headers['x-google-client-id'];
  const azureErr = new URL(location.href).searchParams.get('error');

  useEffect(() => {
    if (azureToken) {
      actions.auth.loginWithAzure(azureToken)
        .finally(() => {
          location.hash = ''; // clear up token from url
          onLogin();
        });
    }
  }, []);

  if (azureToken) return <Spinner/>;

  const showSelfLogin = !azureUrl || new URL(azureUrl).searchParams.has('self');

  const currentUser = store.get('currentUser') || apiErr?.response?.data?.user;

  // Handle unverified email
  if (currentUser && err?.message === 'Unverified email' || errMsg === 'Unverified email') {
    return (
      <VerifyEmail user={currentUser} />
    );
  }

  // Other errors
  const msg = !loading && currentUser && err?.status === 401
    ? <cms.Text id="sessionExpired" />
    : azureErr; // this case shouldn't happen anymore as we don't sign user in if they are not verified for adminApp

  return (
    <>
      <h4 style={{ fontWeight: 300, marginBottom: 20 }}><cms.Text id='modal.title' /></h4>

      {showSelfLogin && (
        <form onSubmit={submit}>
          <cms.Object id="email" keys="placeholder">
            <Field
              {...inputProps('email')}
              type="email"
              required
              placeholder={cms.text('email.placeholder')}
              icon="envelope"
              autoFocus
              autoComplete="username"
            />
          </cms.Object>

          <cms.Object id="password" keys="placeholder,help">
            <Field
              {...inputProps('password')}
              type="password"
              required
              placeholder={cms.text('password.placeholder')}
              icon="lock"
              help={cms.text('password.help') || 'At least 7 characters'}
              autoComplete="current-password"
            />
          </cms.Object>

          {(msg || errMsg) && (
            <ErrorMessage icon="fa fa-times"> 
              <Markdown as="span" inline>{msg || cms.text(`errors.${errMsg}`) || errMsg}</Markdown>
            </ErrorMessage>
          )}

          <button
            className="btn btn-primary btn-flat btn-block"
            type="submit"
            disabled={loading}
          >
            {loading
              ? <i className="fa fa-spinner fa-spin" />
              : <cms.Text id="submit" />
            }
          </button>
        </form>
      )}

      {showSelfLogin && (
        <div style={{ display: 'flex', justifyContent: 'end' }}>
          <Link to="/forgot-password" className="btn btn-link"><cms.Text id="forgotPassword" /></Link>
        </div>
      )}

      {azureUrl && <AzureButton href={azureUrl} disabled={azureToken || !azureUrl} />}

      {(hashParams.get('error_description')) && (
        <ErrorMessage icon="fa fa-times">
          <Markdown as="span" inline>{hashParams.get('error_description')}</Markdown>
        </ErrorMessage>
      )}

      {googleClientId && <GoogleButton clientId={googleClientId} />}

      <HideBadge className="text-muted" style={{ marginTop: 8 }}>
        <WurdMarkdown id="auth.login.terms" inline />
        <Captcha />
      </HideBadge>
    </>
  );
}