import store from 'store';

export default ({ style }) => {
  const { settings } = store.get();

  if (settings.brand?.logo) return <img src={settings.brand.logo} style={style} alt={settings.companyName} />;

  return (
    <span
      style={{
        fontWeight: 700,
        color: settings.brand?.linkColor,
        filter: 'brightness(.8)',
        fontSize: .67 * style?.height,
        verticalAlign: 'middle',
        marginLeft: '.375rem',
        letterSpacing: '-.0165rem',
        ...style,
      }}
    >
      {settings.companyName}
    </span>
  );
};