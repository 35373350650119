import * as helpers from 'helpers';
import { WurdText } from 'wurd-react';

import UnitLoader from 'components/loaders/unit';
import UnitRentalLoader from 'components/loaders/unit-rental';
import UserLoader from 'components/loaders/user';
import ShortLabel from 'components/short-label';
import SiteLoader from 'components/loaders/site';
import InvoiceLoader from 'components/loaders/invoice';
import AddonLoader from 'components/loaders/addon';
import LabelsListCell from 'plugins/labels/list';

const { stateColors } = helpers.unit;

const styles = {
  jobIcon: { color: stateColors.reserved },
  unitIcon: { color: '#aaa', fontSize: 18, marginTop: 6 },
};


export default function TaskJobListItem({
  job,
  hideOwner,
  hideUnit,
  hideSite,
}) {
  const { targetType, targetId, dueDate } = job.result;
  const { title } = job.data;

  return (
    <>
      <div role="cell" style={{ width: 30 }}>
        <div><i className="far fa-fw fa-clipboard-check" style={styles.jobIcon} /></div>
      </div>

      <div role="cell">
        <div>
          <strong>{title}</strong>
        </div>

        <div>
          {targetType === 'user' && !hideOwner && (
            <UserLoader id={targetId}>
              {(user) => user.name}
            </UserLoader>
          )}

          {targetType === 'unit' && !hideUnit && (
            <UnitLoader id={targetId}>
              {(unit) => (
                <>
                  <WurdText id="common.quickAccess.unit" vars={{ name: unit.name }} />
                  {!hideSite && (
                    <span>, <SiteLoader id={unit.siteId}>{site => helpers.ui.getLangText(site.title)}</SiteLoader></span>
                  )}
                </>
              )}
            </UnitLoader>
          )}

          {targetType === 'unitRental' && !hideUnit && (
            <UnitRentalLoader id={targetId} include={['unit', 'owner']}>
              {(unitRental) => (
                <>
                  {!hideUnit && (
                    <>
                      <WurdText id="common.quickAccess.unit" vars={{ name: unitRental.unit.name }} />
                      {!hideSite && (
                        <span>, <SiteLoader id={unitRental.siteId}>{site => helpers.ui.getLangText(site.title)}</SiteLoader></span>
                      )}
                      {' / '}{unitRental.owner?.name}
                    </>
                  )}
                </>
              )}
            </UnitRentalLoader>
          )}

          {targetType === 'invoice' && (
            <InvoiceLoader id={targetId} include="owner">
              {(invoice) => (
                <>
                  <WurdText id="common.quickAccess.invoice" vars={{ sid: helpers.ui.sid(invoice) }} />
                  {!hideSite && (
                    <span>, <SiteLoader id={invoice.siteId}>{site => helpers.ui.getLangText(site.title)}</SiteLoader></span>
                  )}
                </>
              )}
            </InvoiceLoader>
          )}

          {targetType === 'addon' && (
            <AddonLoader id={targetId}>
              {(addon) => (
                <>
                  Addon: {helpers.ui.getLangText(addon.addonConfig.title)}
                  {!hideSite && (
                    <span>, <SiteLoader id={addon.siteId}>{site => helpers.ui.getLangText(site.title)}</SiteLoader></span>
                  )}
                </>
              )}
            </AddonLoader>
          )}
        </div>
      </div>

      <div role="cell">
        <div title="Due date">
          {dueDate && helpers.ui.date(dueDate)}
        </div>
      </div>

      <div role="cell" style={{ textAlign: 'right' }}>
        {job.error && (
          <div>
            <ShortLabel bsType="danger" width="min(200px, 25vw)">{job.error}</ShortLabel>
          </div>
        )}
      </div>

      <div role="cell" title="Labels" style={{ width: 0, padding: 0, position: 'relative' }}>
        <LabelsListCell labels={job.labels} />
      </div>
    </>
  );
}
