import React from 'react'
import styled from '@emotion/styled';


export const Dl = styled.dl`
  margin-bottom: 0px !important;
  margin-top: 0px !important;
  display: flex;
  flex-wrap: wrap;

  div:empty {
    display: none;
  }

  div {
    max-height: 330px;
    overflow: hidden;
    position: relative;
    &::after {
      position: absolute;
      pointer-events: none;
      inset: 87.5% 0 0 0;
      content: "";
      background-image: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 100%
      );
    }
  }

  .col {
    margin-bottom: 24px;
    border-right: 1px solid #0003;
  }

  /* xs */
  @media (max-width: 767px) {
    flex-direction: column;
    flex-wrap: nowrap;
    .col {
      border-right: none;
    }
  }

  /* sm, md */
  @media (min-width: 768px) and (max-width: 1199px) {
    .col-sm-6:nth-of-type(2n) {
      border-right: none;
    }

    .col-sm-6:last-of-type {
      border-right: none;
    }
  }

  /* lg */
  @media (min-width: 1200px) {
    .col-lg-4:nth-of-type(3n) {
      border-right: none;
    }

    .col-lg-4:last-of-type {
      border-right: none;
    }
  }

dt {
  color: var(--bs-gray);
  font-size: 13px;
  font-weight: 300;
  line-height: 20px;
  letter-spacing: -0.14px;
}

dd {
  color: var(--bs-black);
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.153px;
  word-wrap: break-word;
}`;

export default function DetailList({ children, className }) {
  return(
    <Dl className={`row ${className || ''}`}>
      {React.Children.toArray(children).reduce((cs, c) => {
        switch (c.props.type || c.type) {
          case 'dd': return [...cs.slice(0, -1), <>{cs.at(-1).props.children}{c}</>];
          default: return [...cs, <>{c}</>];
        }
      }, [])
      .map((c,i) => <div key={i} className="col col-sm-6 col-lg-4">{c.props.children}</div>)}
    </Dl>
  )
}
