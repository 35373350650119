import wurd from 'wurd-react';
import { pick } from 'lodash';

import store from 'store';
import * as helpers from 'helpers';

import ModalForm from 'components/form2/modal-form';
import { Field, MultiLangText, ImageInput, CurrencyInput } from 'components/form2';

const defaultFields = [
  'groupId',
  'image',
  'imageFit',
  'title',
  'subtitle',
  'code',
  'hidden',
];


export default function UnitTypeDetailsForm({
  site,
  unitType,
  fields = defaultFields,
  ...rest
}) {
  const { settings } = store.get();

  return (
    <ModalForm
      {...rest}
      fields={fields}
      initialValue={pick(unitType, fields)}
      wurdSection="unitType"
    >
      {fieldProps => (
        <>
          {site.unitTypeGroups.length > 0 && (
            <Field {...fieldProps('groupId')}>
              <select>
                <option value="">[{wurd.text('common.phrases.none')}]</option>
                {site.unitTypeGroups.map(unitTypeGroup => (
                  <option key={unitTypeGroup.id} value={unitTypeGroup.id}>{helpers.ui.getLangText(unitTypeGroup.title)}</option>
                ))}
              </select>
            </Field>
          )}

          <Field {...fieldProps('title')} required>
            <MultiLangText />
          </Field>

          <Field {...fieldProps('subtitle')}>
            <MultiLangText />
          </Field>

          <Field {...fieldProps('image')}>
            <ImageInput
              imageFitProps={fieldProps('imageFit')}
            />
          </Field>

          <hr />

          <Field {...fieldProps('code')} />

          <Field {...fieldProps('hidden')} type="checkbox" />
        </>
      )}
    </ModalForm>
  );
}
