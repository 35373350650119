import moment from 'moment-timezone';
import wurd from 'wurd-react';

import * as helpers from '../../helpers';

import ListFilter from '../filter/list';
import DateFilter from '../filter/date';
import LabelsFilter from 'plugins/labels/filter';

const cms = wurd.block('unitJobList.filters');

const orderStates = ['notSubmitted', 'cancelled', 'abandoned'];
const states = ['ready', 'completed', ...orderStates, 'failed'];
const types = ['unit_moveIn', 'unit_moveOut', 'unit_transfer', 'task', 'unitRental_updatePrice'];


export default function UnitJobListFilters({ listQuery, hideSite }) {
  const { params } = listQuery;

  function onChange(key, value) {
    let newParams;

    switch (key) {
      case 'daterange':
        newParams = {
          start: value ? moment(value.start).format('YYYY-MM-DD') : null,
          end: value ? moment(value.end).format('YYYY-MM-DD') : null,
        };
        break;

      case 'state':
        newParams = {
          state: null,
          orderState: value === 'completed' ? 'completed' : null,
          ...value && { [orderStates.includes(value) ? 'orderState' : 'state']: value },
        };
        break;

      default:
        newParams = { [key]: value };
    }

    listQuery.update({
      offset: 0,
      ...newParams,
    });
  }

  const setSiteId = (val) => onChange('siteId', val);
  const setState = (val) => onChange('state', val);
  const setType = (val) => onChange('type', val);
  const setDateRange = (val) => onChange('daterange', val);

  const daterange = (params.start && params.end)
    ? { start: params.start, end: params.end }
    : null;

  return (
    <>
      {/*{!hideSite && (
        <cms.Object id="site" keys="title,all">
          <SiteFilter
            title={cms.text('site.title')}
            active={params.siteId}
            onSelect={setSiteId}
          />
        </cms.Object>
      )}*/}

      <cms.Object id="state" keys={['title', 'all'].concat(states)}>
        <ListFilter
          title={cms.text('state.title')}
          active={params.orderState || params.state || null}
          onSelect={setState}
        >
          {states.map((state) => (
            <li key={state} value={state}>{cms.text(`state.${state}`)}</li>
          ))}
        </ListFilter>
      </cms.Object>

      <cms.Object id="type" keys={`title,all,${types.join(',')}`}>
        <ListFilter
          title={cms.text('type.title')}
          active={params.type}
          onSelect={setType}
        >
          <li value={null}>{cms.text('type.all')}</li>
          {types.map((type) => (
            <li key={type} value={type}>{cms.text(`type.${type}`)}</li>
          ))}
        </ListFilter>
      </cms.Object>

      <cms.Object id="daterange" keys="title">
        <DateFilter
          title={cms.text('daterange.title')}
          range={daterange}
          onSelect={setDateRange}
        />
      </cms.Object>

      <LabelsFilter
        active={params.labels}
        onSelect={(val) => onChange('labels', val)}
        labels={helpers.settings.get().jobLabels}
      />
    </>
  );
}
