/* eslint-disable jsx-a11y/anchor-is-valid */

import { useState } from 'react';
import wurd from 'wurd-react';
import moment from 'moment-timezone';

import { time, date, datetime } from '../../helpers/ui';

import Alert from '../alert';


export default function JobStep({
  title,
  step,
  faIcon = 'far fa-circle',
  helpWurdId,
  open = false,
  children,
}) {
  const [showResults, setShowResults] = useState(open);

  function toggleResults() {
    setShowResults(!showResults);
  }

  function render_ready() {
    return (
      <div className="panel panel-default sg-active-step">
        <div className="panel-heading">
          <a className="">
            {helpWurdId && (
              <div className="pull-right">
                <a style={{ margin: 0, padding: 0 }} href={wurd.text(helpWurdId)} target="_blank" rel="noopener noreferrer" className="hidden-print">
                  <i className="far fa-fw fa-question-circle" title="Help" />
                </a>
              </div>
            )}

            <i className={`fa-fw ${faIcon}`} />&nbsp;&nbsp;
            {title}
          </a>
        </div>
        <div className="panel-body">
          {step.error && (
            <Alert type="danger">{step.error}</Alert>
          )}

          {children}
        </div>
      </div>
    );
  }

  function render_ready_delayed() {
    return (
      <div className="panel panel-default sg-active-step">
        <div className="panel-heading">
          <a className="">
            <div className="pull-right">
              <small title="Delayed until">{datetime(step.delayUntil)}</small>
            </div>

            <i className="far fa-fw fa-clock" />&nbsp;&nbsp;
            {title}
          </a>
        </div>
        <div className="panel-body">
          {children}
        </div>
      </div>
    );
  }

  function render_completed() {
    const { completedAt } = step;

    const createdToday = moment(completedAt).isSame(new Date(), 'day');

    const dateStr = createdToday ? time(completedAt) : date(completedAt, 'D MMM');

    return (
      <li className="activity__item">
        <i className="fa fa-check green" />
        <span className="date">{dateStr}</span>

        <div
          className="content"
          role="button"
          tabIndex={0}
          style={{ cursor: children ? 'pointer' : '' }}
          onClick={toggleResults}
          onKeyPress={toggleResults}
        >
          {title}

          {completedAt && (
            <p><small className="text-muted">{datetime(completedAt)}</small></p>
          )}

          {showResults && children && (
            <div className="panel-body">
              {children}
            </div>
          )}
        </div>
      </li>
    );
  }


  if (step.state === 'ready') {
    return step.delayUntil ? render_ready_delayed() : render_ready();
  }

  return render_completed();
}
