import wurd, { WurdText } from 'wurd-react';
import ModalForm from '../modal-form';
import Field from '../horizontal-field';
import MultiLangText from '../multi-lang-text';
import Currency from '../currency';
import InputGroup from '../input-group';
import store from 'store';
import AccountingCodeSelect from '../accounting-code-select';
import RadioList from 'components/form2/radiolist';


export default function SiteLateFeeForm(props) {
  return (
    <ModalForm
      wurdSection="site.lateFees"
      {...props}
    >
      {(getProps, formValue) => (
        <>
          <Field {...getProps('desc')} required>
            <MultiLangText autoFocus />
          </Field>

          <Field
            {...getProps('days')}
            wurdKeys="label,placeholder,help,help_update"
            help={['help', 'help_' + props.mode].map(s => wurd.text('site.lateFees.days.' + s)).filter(Boolean).join('<br>')}
            type="number"
            step="1"
            min="0"
            max="90"
          />

          <Field {...getProps('type')} defaultValue="abs" className={null}>
            <RadioList>
              {['abs', 'pct'].map(v => ({
                value: v,
                title: <WurdText id={`site.lateFees.type.${v}.title`} vars={{ symbol: store.get('settings').currency }} />,
                description: <WurdText id={`site.lateFees.type.${v}.info`} type="p" className="text-muted" />
              }))}
            </RadioList>
          </Field>

          <Field {...getProps('amount')}>
            {formValue.type === 'pct'
              ? <InputGroup before="%" type="number" min="0" max="100" autoComplete="off" step="any" />
              : <Currency />}
          </Field>

          <Field {...getProps('threshold')}>
            <Currency />
          </Field>

          <Field {...getProps('accountingCodeId')}>
            <AccountingCodeSelect />
          </Field>
        </>
      )}
    </ModalForm>
  );
}
