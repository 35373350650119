import styled from '@emotion/styled';

const Container = styled.div`
  display: flex;
  align-items: center;
  border-top: 1px solid rgb(221, 221, 221);
  padding: 8px;
  font-size: 15.6px;

  .image {
    width: 65px;

    img {
      width: 50px;
      height: 50px;
      background: #fff;
      object-fit: contain;
    }
  }

  .main {
    flex: 3;

    .title {
      font-weight: 600;
    }
  
    .subtitle {
      margin-top: 4px;
      font-size: .8em;

      ul {
        margin: 0;
        padding: 0;

        li {
          display: inline;
          line-height: 1em;

          :not(:last-child)::after {
            content: ' ⸱ ';
            margin-right: 4px;
            margin-left: 4px;
            color: #bbb;
          }

          i {
            color: #bbb;
          }
        }
      }
    }
  }

  .extra1 {
    flex: 1;
    text-align: right;
  }

  .extra2 {
    flex: 1;
    text-align: right;
  }
`;


export default function TableListRow({
  image,
  title,
  subtitle, // Secondary info that appears below the title; can be a <ul> of datapoints
  extra1, // Secondary info such as state/price which is shown on the right
  extra2, // Tertiary info such as user name, which is not shown on mobile
  ...rest
}) {
  return (
    <Container {...rest}>
      <div className="image" role="cell">
        <img src={image} alt="" />
      </div>

      <div className="main" role="cell">
        <div className="title">{title}</div>

        <div className="subtitle">{subtitle}</div>
      </div>

      {extra2 && (
        <div className="extra1 hidden-xs" role="cell">
          {extra2}
        </div>
      )}

      {extra1 && (
        <div className="extra1" role="cell">
          {extra1}
        </div>
      )}
    </Container>
  );
}
