function getApiUrl() {
  let url = process.env.REACT_APP_API_URL || '/api';

  // If REACT_APP_API_URL is a relative URL (e.g. `/api`), we need to add the hostname etc.
  if (!url.startsWith('http')) {
    url = new URL(window.location).origin + url;
  }

  return url;
}

export const apiUrl = getApiUrl();

export const publicUrl = process.env.PUBLIC_URL;
export const basePath = process.env.REACT_APP_BASE_PATH || '/admin';

export const userAppPreviewUrl = window.location.hostname === 'localhost' ? 'http://localhost:7400' : window.location.origin;

export const nonce = document.querySelector('script[nonce]')?.nonce;

// langs available in admin app - https://manage.wurd.io/projects/storeganise-admin/languages - also update utils/language moment locales
export const adminLangs = [
  'en',
  'de',
  'es',
  'fr',
  'ja',
  'ko',
  'no',
  'pt',
  'sv',
  'zh',
  'he',
  'th',
];

// supported langs in storeganise customer apps - https://manage.wurd.io/projects/sg-userapp/languages
export const userLangs = [
  'bg', // Bulgarian
  'cs', // Czech
  'da', // Danish
  'de', // German
  'en', // English
  'es', // Spanish
  'es-MX', // Spanish - Mexico (should we use 'es-419' standard code for this?)
  'fi', // Finnish
  'fr', // French
  'it', // Italian
  'ja', // Japanese
  'ko', // Korean
  'lt', // Lithuanian
  'nl', // Dutch
  'no', // Norwegian (we should probably change to 'nb', more common)
  'pt', // Portuguese
  'ro', // Romanian
  'sk', // Slovak
  'sl', // Slovenian
  'sv', // Swedish
  'th', // Thai
  'zh', // Chinese (Traditional): Hong Kong
  'zh-CN', // Chinese (Simplified): Mainland China
  'he', // Hebrew
];

export const wurdSections = [
  'auth',
  'business',
  'market',
  'common',
  'visit',
  'user',
  'item',
  'invoice',
  'site',
  'unit',
  'unitRental',
  'settings',
  'marketSettings',
  'actions',
  'visitList',
  'visitView',
  'userList',
  'userView',
  'itemList',
  'itemView',
  'invoiceList',
  'invoiceView',
  'unitList',
  'unitView',
  'activity',
  'dashboard',
  'job',
  'jobs',
  'jobList',
  'jobView',
  'valetOrderList',
  'valetOrder',
  'unitOrderList',
  'unitOrder',
  'unitJobList',
  'chargesPlugin',
  'accountPlugin',
  'siteList',
  'siteView',
  'unitJobs',
  'unitType',
  'unitTypeList',
  'unitTypeView',
  'billing',
  'paymentList',
  'addons',
  'developer',
];
