import wurd, { WurdText } from 'wurd-react';


const style = {
  wordBreak: 'break-all',
};

export function getErrId(err) {
  // general error types e.g. Forbidden, Unauthorized
  if (err.name && err.name !== 'Error') return `common.errors.${err.name}`;

  // wurd key returned as error message e.g. item.cannotUnassignBulky
  if (/^[a-z][a-zA-Z_-]+(\.[a-z][a-zA-Z_-]+)+$/.test(err.message)) return err.message;

  // message text e.g 'Unverified email'
  const errMsg = err.message.trimStart()
    .replace(/[a-f0-9]{24}/g, '') // remove object ids to make it generic (we should avoid site.code or such thing in error message)
    .replace(/[^a-zA-Z_ -]/g, '') // remove punctuation
    .replace(/ +./g, c => c[c.length - 1].toUpperCase()) // camelCase
    .replace(/^./, c => c.toLowerCase()) // lower first char
    .trimEnd();

  return `common.errors.${errMsg}`;
}


export default function ErrMsg(err) {
  if (typeof err.message !== 'string') return err.message; // err.message is already a WurdText

  const errKey = getErrId(err);

  return wurd.editMode || wurd.text(errKey) ? <WurdText id={errKey} vars={err.errors} style={style} /> : <span style={style}>{err.message}</span>;
}
