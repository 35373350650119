import CurrentOccupancyChart from 'components/site-detail/charts/current-occupancy';


export default function OccupancySidebarSummary({
  unitType,
}) {
  //Object.values(unitType.occupancy || {}).reduce((a, b) => a + b, 0) > 0;
  return (
    <div className="text-center" style={{
      background: '#fff',
      padding: 10,
    }}>
      <CurrentOccupancyChart
        site={unitType}
        getStateLink={state => `/unit-types/${unitType.id}/units?state=${state}`}
        hideLegend
      />
    </div>
  );
}
