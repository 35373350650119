import wurd from 'wurd-react';

import { ModalForm, Field, MultiLangText } from 'components/form2';


const cms = wurd.block('unitType.annotations');

const annotationTypes = {
  text: {
    positions: [
      'price_above',
      'price_below',
    ],
  },
  ribbon: {
    positions: [
      'row_top-right',
    ],
  },
};

const annotationColors = [
  'default',
  'brand',
  'muted',
  'red',
  'yellow',
  'green',
];


export default function UnitTypeAnnotationsForm(props) {
  return (
    <ModalForm
      wurdSection={cms.id()}
      {...props}
    >
      {(fieldProps, formValue) => (
        <>
          <Field
            {...fieldProps('type')}
            required
          >
            <select>
              {Object.keys(annotationTypes).map((type) => (
                <option key={type}>{type}</option>
              ))}
            </select>
          </Field>

          <Field
            {...fieldProps('position')}
            required
          >
            <select>
              <option value=""></option>
              {annotationTypes[formValue.type]?.positions.map((type) => (
                <option key={type}>{type}</option>
              ))}
            </select>
          </Field>

          <Field
            {...fieldProps('text')}
          >
            <MultiLangText />
          </Field>

          <Field
            {...fieldProps('color')}
            required
          >
            <select>
              {annotationColors.map((type) => (
                <option key={type}>{type}</option>
              ))}
            </select>
          </Field>
        </>
      )}
    </ModalForm>
  );
}
