import wurd from 'wurd-react';
import * as helpers from 'helpers';


const cms = wurd.block('common.plugins.customFields');

const isImage = name => /\.(gif|jpe?g|tiff|png|webp|bmp|svg)$/i.test(name);

const linkProps = {
  target: '_blank',
  rel: 'noreferrer noopener',
  style: { overflow: 'hidden', textOverflow: 'ellipsis' },
};

export default function CustomFieldDetailValue({ value, type, secret }) {
  if (type === 'file' && value) {
    return (
      <a href={value.url} {...linkProps}>
        {isImage(value.name) && (
          <img
            src={value.url}
            alt={value.name}
            loading="lazy"
            style={{ display: 'inline-block', verticalAlign: 'middle', objectFit: 'cover', width: 30, height: 30, marginRight: 8 }}
          />
        )}
        <span>{value.name || <cms.Text id="unnamedFile" />}</span>
      </a>
    )
  }

  if (secret && `${value ?? ''}`) return (
    <cms.Text
      id="secret"
      vars={{ first4: `${value}`.slice(0, 4), last4: `${value}`.slice(-4) }}
      style={{ filter: 'opacity(.5)', fontStyle: 'italic' }}
    />
  );

  if (Array.isArray(value)) return value.join(', ');
  if (type === 'date') return helpers.ui.date(value);
  if (type === 'date-time') return helpers.ui.datetime(value);
  if (type === 'url') return <a href={value} {...linkProps}>{value}</a>;
  if (type === 'email') return <a href={`mailto:${value}`} {...linkProps}>{value}</a>;
  if (type === 'tel') return <a href={`tel:${value}`}>{value}</a>;
  if (type === 'boolean' && typeof value === 'boolean') return `${value}`;
  return value ?? <cms.Text id="noValue" />;
}
