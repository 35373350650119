import MoveInItem from './items/move-in';
import MoveOutItem from './items/move-out';
import TransferItem from './items/transfer';
import TaskItem from './items/task';
import UnitRentalPriceUpdateItem from './items/unit-rental-price-update';


const itemComponents = {
  unit_moveIn: MoveInItem,
  unit_moveOut: MoveOutItem,
  unit_transfer: TransferItem,
  task: TaskItem,
  unitRental_updatePrice: UnitRentalPriceUpdateItem,
};


export default function UnitJobListItem({
  job,
  hideSite = false,
  hideOwner = false,
}) {
  const Item = itemComponents[job.type];
  if (!Item) return null;

  return (
    <Item
      job={job}
      hideSite={hideSite}
      hideOwner={hideOwner}
    />
  );
}