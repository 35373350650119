import wurd from 'wurd-react';
import * as actions from 'actions';
import store from 'store';
import JobStep from '../../job-step2';
import Alert from '../../../alert';
import ApiButton from '../../../api-button';
import OperationsBar from '../operations-bar';

const cms = wurd.block('unitOrder.steps.identity_verify');


export default function UniveMoveInJobSteps_InvoiceConfirm({
  site,
  job,
  step,
  onChange,
  modal,
}) {
  async function completeStep() {
    const confirmed = window.confirm(cms.text('completeStep.confirm'));
    if (!confirmed) return;
  
    await actions.jobs.updateStep(job.id, step.id, {
      state: 'completed',
    });

    onChange();
  }
  async function cancelJob() {
    const confirmed = window.confirm(cms.text('cancelJob.confirm'));
    if (!confirmed) return;
  
    await actions.jobs.runOperation(job.id, 'cancel');

    onChange();
  }
  const { settings } = store.get();
  const verificationLink = step.result.verificationSession && `https://dashboard.stripe.com${settings._stripe?.publishableKey.startsWith('pk_test') ? '/test' : ''}/identity/verification-sessions/${step.result.verificationSession}`;

  function render_ready() {
    return (
      <JobStep
        step={step}
        title={<cms.Text id={`${step.requiresInput ? 'requiresInput' : 'ready'}.title`} />}
      >
        <div style={{ margin: '20px 20px 5px', display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '10%' }}>
          {verificationLink && (
            <a
              type="button"
              className="btn btn-link"
              target="_blank"
              href={verificationLink}
            >
              <i className="far fa-external-link" />{' '}
              <cms.Text id="viewVerification.btn" />
            </a>
          )}
          <cms.Object id="cancelJob" keys="btn,confirm">
            <ApiButton onClick={cancelJob} bsType="link">
              <i className="icon far fa-trash" />{' '}
              <cms.Text id="cancelJob.btn" />
            </ApiButton>
          </cms.Object>
          <cms.Object id="completeStep" keys="btn,confirm">
            <ApiButton onClick={completeStep} bsType="warning">
              <cms.Text id="completeStep.btn" />
            </ApiButton>
          </cms.Object>
        </div>
      </JobStep>
    );
  }

  function render_completed() {
    const { invoiceId } = job.result;

    return (
      <JobStep
        step={step}
        title={<cms.Text id="completed.title" />}
      >
        {verificationLink && (
          <>
            <a href={verificationLink} target="_blank">{step.result.verificationSession}</a> <em className="text-danger fw-normal">{step.error}</em>
          </>
        )}
      </JobStep>
    );
  }

  if (step.state === 'ready') return render_ready();
  if (step.state === 'completed') return render_completed();

  return null;
}
