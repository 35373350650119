import { Button } from 'react-bootstrap';
import { SCALE_BY } from '../Constants';

const zoomIconStyle = { width: 10, height: 10, marginTop: -2 };


export default function SitemapZoomControls({ style, handleZoom, reset }) {

  return (
    <div className="btn-group-vertical" style={style}>
      <Button onClick={reset}>
        <i className="fa fa-location" aria-label="Center" style={zoomIconStyle} />
      </Button>
      <Button onClick={() => handleZoom(SCALE_BY)}> 
        <i className="fa fa-plus" aria-label="Zoom in" style={zoomIconStyle} />
      </Button>
      <Button onClick={() => handleZoom(-SCALE_BY)}>
        <i className="fa fa-minus" aria-label="Zoom out" style={zoomIconStyle} />
      </Button>
    </div>
  );

}
