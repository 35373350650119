export * as hero1 from './hero';
export * as hero2 from './hero';

export * as features1 from './features';
export * as features2 from './features';
export * as blogs1 from './features';
export * as prices1 from './features';

export * as header1 from './header';

export * as footer1 from './footer-cols';
export * as footer2 from './footer-links';

export * as text1 from './text';

export * as spacer1 from './spacer';

export * as faq1 from './faq';

export * as sites1 from './sites';

export * as addon from './addon';


// aliases
export default {
  header1: 'header1',

  hero1: 'hero-overlay',
  hero2: 'hero-split',

  text1: 'text1',
  features1: 'features-grid',
  features2: 'features-split',
  blogs1: 'blogs1',
  prices1: 'prices1',
  faq1: 'faq1',

  spacer1: 'spacer-lg',

  footer1: 'footer-cols',
  footer2: 'footer-links',

  sites1: 'sites1',

  //addon: 'addon', // Removed here as we fetch addons separately in the BlockSelectorModal
};
