import crudList from './plugins/crud-list';


const url = `/v1/admin/unit-types`;

const actions = {};

actions.annotations = function (unitTypeId) {
  return crudList(`${url}/${unitTypeId}/annotations`);
};

export default actions;
