import wurd, { WurdText } from 'wurd-react';
import { useEffect } from 'react';

import { userAppPreviewUrl } from 'config';
import * as actions from 'actions';
import * as helpers from 'helpers';

import Section from 'components/section';
import DetailList from 'components/detail-list';
import MarkdownEditor from 'components/markdown-editor';
import LangText from 'components/lang-text';
import StateLabel from 'components/state-label';
import CustomFieldsSection from 'plugins/custom-fields/section';
import Features from '../features';
import OccupancyPrices from '../occupancy-prices';
import DetailsForm from './details-form';
import PricingForm from './pricing-form';
import Annotations from '../annotations';


const Label = ({ id }) => (
  <WurdText type="dt" id={`unitType.${id}.label`} />
);

function UserappPreview({ path }) {
  const src = `${userAppPreviewUrl}/account/previews/${path}`;

  useEffect(() => {
    try {
      const iframe = document.getElementById('sgUserappPreviewIframe');
      
      iframe.contentWindow.onload = function () {
        console.log('loaded iframe content');
      
        const observer = new MutationObserver(function (mutationsList, observer) {
          const contentHeight = iframe.contentWindow.document.body.scrollHeight;
          console.log('iframe doc height', contentHeight);
      
          if (contentHeight > 0) {
            iframe.height = contentHeight + 'px';
          }
        });
      
        observer.observe(iframe.contentWindow.document.body, { childList: true, subtree: true });
      };
    } catch (err) {
      console.warn('Unable to interact with sgUserappPreviewIframe; must be on same origin');
    }
  }, []);

  return (
    <div style={{
      position: 'sticky',
      top: 50,
      borderRadius: 4,
      boxShadow: '0px 6px 6px 0px rgba(0, 0, 0, 0.06)',
      zIndex: 999,
      marginBottom: '2em',
      backgroundColor: '#f6f6f6',
      border: '1px solid #f6f6f6',
      lineHeight: 0, // avoids a small gap at the bottom
    }}>
      <iframe
        id="sgUserappPreviewIframe"
        src={src}
        width="100%"
        height="228" // estimate; this gets updated dynamically on iframe load
        style={{ border: 0 }}
      />
    </div>
  );
}


export default function SiteUnitTypeDetail({
  cms,
  site,
  unitType,
  modal,
  onChange,
}) {
  function editDetails() {
    modal.open(
      <DetailsForm
        site={site}
        unitType={unitType}
        siteId={unitType.siteId}
        onSubmit={data => actions.update('unit-types', unitType.id, data)}
        onSuccess={onChange}
        closeModal={modal.close}
        mode="update"
      />,
    );
  }

  function editPricing() {
    modal.open(
      <PricingForm
        site={site}
        unitType={unitType}
        siteId={unitType.siteId}
        onSubmit={data => actions.update('unit-types', unitType.id, data)}
        onSuccess={onChange}
        closeModal={modal.close}
        mode="update"
      />,
    );
  }

  return (
    <>
      {/* {Object.values(unitType.occupancy || {}).reduce((a, b) => a + b, 0) > 0 && (
        <Section cms={cms.block('overview')}>
          <div className="text-center">
            <CurrentOccupancyChart
              site={unitType}
              getStateLink={state => `/unit-types/${unitType.id}/units?state=${state}`}
            />
          </div>
        </Section>
      )} */}

      <UserappPreview
        path={`unit-type-row/${site.code}/${unitType.code}?v=${unitType.updated}`}
      />

      <Section
        cms={cms.block('details')}
        onClickEdit={editDetails}
      >
        {site.unitTypeGroups.length > 0 && (
          <DetailList>
            <Label id="groupId" />
            <dd>
              {helpers.ui.getLangText(
                site.unitTypeGroups.find(utg => utg.id === unitType.groupId)?.title
              ) || wurd.text('common.phrases.none')}
            </dd>
          </DetailList>
        )}

        <DetailList>
          <Label id="title" />
          <dd><LangText obj={unitType.title} /></dd>

          <Label id="subtitle" />
          <dd><LangText obj={unitType.subtitle} /></dd>
        </DetailList>

        <DetailList>
          <Label id="code" />
          <dd>{unitType.code}</dd>

          <Label id="hidden" />
          <dd>
            {unitType.hidden 
              ? <StateLabel type="warning"><WurdText id="unitType.hidden.true" /></StateLabel>
              : <StateLabel type="success"><WurdText id="unitType.hidden.false" /></StateLabel>}
          </dd>
        </DetailList>
      </Section>

      <Section 
        cms={cms.block('pricing')}
        onClickEdit={editPricing}
      >
        <DetailList>
          <Label id="deposit" />
          <dd>{helpers.ui.priceChange(unitType.deposit_display || unitType.deposit, unitType.deposit)}</dd>

          <Label id="price" />
          <dd>
            {helpers.ui.priceChange(helpers.unitType.currentPrice(unitType), unitType.price_before || unitType.price, helpers.ui.rentPeriod())}
            {helpers.unitType.currentPrice(unitType) != unitType.price && unitType.price_dynamic != null && (
              <StateLabel type="info" style={{ marginLeft: '.5rem' }}><cms.Text id="dynamicPriceApplied" /></StateLabel>
            )}
          </dd>
        </DetailList>
      </Section>

      <OccupancyPrices
        modal={modal}
        cms={cms.block('occupancyPrices')}
        unitType={unitType}
        onChange={onChange}
      />

      <Annotations
        modal={modal}
        cms={cms.block('annotations')}
        unitType={unitType}
        onChange={onChange}
      />

      <Features
        modal={modal}
        cms={cms.block('features')}
        unitType={unitType}
        onChange={onChange}
      />

      <MarkdownEditor
        cms={cms.block('info')}
        content={unitType.info}
        onChange={async (info) => {
          await actions.update('unit-types', unitType.id, { info });
          onChange();
        }}
      />

      <CustomFieldsSection
        cms={cms.block('customFields')}
        modelName="unitType"
        model={unitType}
        modal={modal}
      />
    </>
  );
}
