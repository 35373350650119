/**
 * A checklist form control.
 * Renders child components as a list of items with checkboxes.  Each child should have an 
 * "id" property. This is added/removed from an array on the form and used to control whether 
 * the item is checked or not.
 */
import PropTypes from 'prop-types';
import styled from '@emotion/styled';


const Wrapper = styled.div({
  maxHeight: 300,
  overflow: 'auto',

  '&.cl-sm': {
    'label': {
      padding: '8px 12px',
    }
  },

  'label': {
    fontWeight: 'normal',
    display: 'flex',
    alignItems: 'start',
    flex: 1,

    'input[type="checkbox"]': {
      marginRight: '1rem'
    }
  }
});

export default function FormChecklist({ children, value, name, onChange, className, ...props }) {
  function toggleSelected(itemId) {
    let selectedIds = value || [];

    if (selectedIds.includes(itemId)) {
      selectedIds = selectedIds.filter(id => id !== itemId);
    } else {
      selectedIds = selectedIds.concat(itemId);
    }

    onChange({
      target: {
        name,
        value: selectedIds
      }
    });
  }

  return (
    <Wrapper className={`list-group ${className || ''}`} {...props}>
      {children.map(item => {
        if (!item) return null;
        
        const itemId = item.props.id;

        return (
          <label key={itemId} className="list-group-item">
            <input
              type="checkbox"
              checked={value?.includes?.(itemId)}
              onChange={() => toggleSelected(itemId)}
            />

            {item}
          </label>
        );
      })}
    </Wrapper>
  );
}


FormChecklist.propTypes = {
  //onChange: PropTypes.func.isRequired,
  value: PropTypes.array,
};