import wurd from 'wurd-react';
import { pick } from 'lodash';

import store from 'store';
import * as helpers from 'helpers';

import ModalForm from 'components/form2/modal-form';
import { Field, MultiLangText, ImageInput, CurrencyInput } from 'components/form2';

const defaultFields = [
  'deposit',
  'deposit_display',
  'price',
  'price_before',
  'price_display',
];


export default function UnitTypePricingForm({
  site,
  unitType,
  fields = defaultFields,
  ...rest
}) {
  const { settings } = store.get();

  return (
    <ModalForm
      {...rest}
      fields={fields}
      initialValue={pick(unitType, fields)}
      wurdSection="unitType"
    >
      {fieldProps => (
        <>
          <Field {...fieldProps('deposit')} type="number" step="any" required>
            <CurrencyInput min="0" />
          </Field>

          <Field {...fieldProps('deposit_display')} type="number" step="any">
            <CurrencyInput min="0" />
          </Field>

          <Field {...fieldProps('price')} type="number" step="any" required>
            <CurrencyInput min="0" after={wurd.text(`common.phrases.rentPer_${settings.invoicePeriod}`)} />
          </Field>

          <Field {...fieldProps('price_display')} type="number" step="any">
            <CurrencyInput min="0" after={wurd.text(`common.phrases.rentPer_${settings.invoicePeriod}`)} />
          </Field>

          <Field {...fieldProps('price_before')} type="number" step="any">
            <CurrencyInput min="0" after={wurd.text(`common.phrases.rentPer_${settings.invoicePeriod}`)} />
          </Field>
        </>
      )}
    </ModalForm>
  );
}
