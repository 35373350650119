import { useItemLoader } from 'hooks';
import { throttle_fetchOne } from 'actions';

const fetch = throttle_fetchOne();

export default function AddonConfigLoader({
  id,
  include,
  maxAge,
  loading = <i className="fas fa-spinner fa-spin" />,
  fallback = <i className="fas fa-question-circle" title="Not found" />,
  children: renderFn,
}) {
  const { item: addonConfig, isLoading, refetch } = useItemLoader('addon-configs', id, {
    include,
    maxAge,
    fetch,
  });

  if (!addonConfig) {
    return isLoading ? loading : fallback;
  }

  return renderFn(addonConfig, { isLoading, refetch });
}
