import { DropdownButton, MenuItem } from 'react-bootstrap';

import * as actions from 'actions';
import * as helpers from 'helpers';

import { ModalForm, Field, CurrencyInput } from 'components/form2';

const wurdIds = [
  'pause',
  'disable',
  'uninstall',
];


export default function AddonDetailMenu({
  modal,
  cms,
  addonConfig,
  addon,
  refetch,
  tasksPlugin,
}) {
  async function pause() {
    modal.open(
      <ModalForm
        onSubmit={async () => {
          return actions.update('addons', addon.id, {
            state: 'paused',
          });
        }}
        onSuccess={async () => {
          modal.close();
          refetch();
        }}
        closeModal={modal.close}
        wurdSection={cms.id('confirmPause')}
      >
        {(fieldProps) => (
          <>
            <cms.Markdown id="confirmPause.text" />
          </>
        )}
      </ModalForm>
    );
  }

  async function disable() {
    modal.open(
      <ModalForm
        onSubmit={async () => {
          return actions.update('addons', addon.id, {
            state: 'disabled',
          });
        }}
        onSuccess={async () => {
          modal.close();
          refetch();
        }}
        closeModal={modal.close}
        wurdSection={cms.id('confirmDisable')}
      >
        {(fieldProps) => (
          <>
            <cms.Markdown id="confirmDisable.text" />
          </>
        )}
      </ModalForm>
    );
  }

  async function uninstall() {
    modal.open(
      <ModalForm
        onSubmit={async () => {
          try {
            await actions.del('addons', addon.id);
          } catch (err) {
            // We expect a 404 error if the addon is successfully uninstalled
            if (err.name !== 'NotFound') throw err;
          } finally {
            console.log('redirect')
            helpers.ui.redirectBack();
          }
        }}
        onSuccess={async () => {
          modal.close();
          refetch();
        }}
        closeModal={modal.close}
        wurdSection={cms.id('confirmUninstall')}
      >
        {(fieldProps) => (
          <>
            <cms.Markdown id="confirmUninstall.text" />
          </>
        )}
      </ModalForm>
    );
  }

  async function editPrice() {
    modal.open(
      <ModalForm
        initialValue={{ price: addon.price }}
        onSubmit={async (data) => {
          return actions.update('addons', addon.id, data);
        }}
        onSuccess={async () => {
          modal.close();
          refetch();
        }}
        closeModal={modal.close}
        wurdSection={cms.id('editPrice')}
      >
        {(fieldProps) => (
          <>
            <Field {...fieldProps('price')} required autoFocus>
              <CurrencyInput min={0} type="number" />
            </Field>
          </>
        )}
      </ModalForm>
    );
  }

  function getMenuItems() {
    const items = [];

    if (addon.state !== 'paused') {
      items.push(
        <MenuItem
          key="pause"
          onSelect={pause}
          title={cms.text('pause')}
        >
          <i className="far fa-fw fa-pause" />&nbsp;&nbsp;&nbsp;{cms.text('pause')}
        </MenuItem>,
      );
    }

    if (addon.state !== 'disabled') {
      items.push(
        <MenuItem
          key="disable"
          onSelect={disable}
          title={cms.text('disable')}
        >
          <i className="far fa-fw fa-minus-circle" />&nbsp;&nbsp;&nbsp;{cms.text('disable')}
        </MenuItem>,
      );
    }

    items.push(
      <MenuItem
        key="uninstall"
        onSelect={uninstall}
        title={cms.text('uninstall')}
      >
        <i className="far fa-fw fa-trash" />&nbsp;&nbsp;&nbsp;{cms.text('uninstall')}
      </MenuItem>,

      <MenuItem divider key="divider" />,

      <tasksPlugin.AddTaskMenuItem key="tasks" />,
    );

    //if (helpers.auth.hasRole('_support')) {
      items.push(
        <MenuItem
          key="editPrice"
          onSelect={editPrice}
          title="Edit price"
        >
          <em className="text-muted">
            <i className="far fa-fw fa-dollar-sign" />&nbsp;&nbsp;&nbsp;Edit price
          </em>
        </MenuItem>,
      );
    //}

    return items;
  }

  const menuItems = getMenuItems();
  if (!menuItems.length) return null;


  return (
    <cms.Object keys={wurdIds.join(',')}>
      <DropdownButton
        title={<i className="fa fa-ellipsis-v" />}
        id="itemMenu"
        bsStyle="link"
        pullRight
        noCaret
      >
        {menuItems}
      </DropdownButton>
    </cms.Object>
  );
}
