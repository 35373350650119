import { ModalForm, Field } from 'components/form2';

export default function AddonConfigWebsiteCspForm(props) {
  return (
    <ModalForm
      wurdSection="addons.config.websiteCsp"
      {...props}
    >
      {(getProps, formValue) => (
        <>
          <Field {...getProps('type')}>
            <select>
              <option value="connectSrc">connect-src</option>
              <option value="fontSrc">font-src</option>
              <option value="frameSrc">frame-src</option>
              <option value="scriptSrc">script-src</option>
              <option value="styleSrc">style-src</option>
            </select>
          </Field>

          <Field {...getProps('content')}><textarea /></Field>
        </>
      )}
    </ModalForm>
  );
}
