import Link from 'components/nested-link';


export default ({ job }) => {
  const { data, result } = job;
  const { noticeDate, startDate, unitRentalId, oldPrice, newPrice } = result;
  console.log(result)

  return (
    <div>
      <div title="startDate">
        {startDate} {noticeDate && <span title="Notice date" style={{ color: '#aaa' }}>({noticeDate})</span>}
      </div>
      <div title="Price change">{oldPrice} → {newPrice}</div>

      <Link to={`/unit-rentals/${unitRentalId}`}>
        {unitRentalId}
      </Link>
    </div>
  );
};
