import { useState } from 'react';
import { intersection } from 'lodash';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { NavDropdown, MenuItem } from 'react-bootstrap';
import wurd, { WurdText, WurdObject } from 'wurd-react';
import styled from '@emotion/styled';

import * as config from '../config';
import * as actions from '../actions';
import * as helpers from '../helpers';
import { currentLang } from 'utils/language';
import store from '../store';
import { showAnalytics } from './analytics';
import QuickAccess from './quick-access';
import { useShortcut, useMobile } from 'hooks';

const cms = wurd.block('common');
const { hasRole } = helpers.auth;


const NavbarCollapse = styled.div({
  maxHeight: 'none!important',
  '@media (min-width: 768px)': {
    '&.navbar-collapse': {
      display: 'flex!important'
    }
  },
  '@media (max-width: 767px)': {
    '.navbar-nav .open .dropdown-menu>li>a': {
      color: '#fffe!important'
    }
  },
  '@media (min-width: 768px) and (max-width: 1199px)': {
    paddingLeft: '32px!important'
  }
});

const UlHorizontal = styled.ul({
  maxHeight: 'none!important',

  '@media (min-width: 768px)': {
    flex: 1,
    display: 'flex',
    whiteSpace: 'nowrap',
    overflowX: 'overlay',
    '&::-webkit-scrollbar': {
      height: 3,
      width: 8,
      // backgroundColor: '#fffc',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#1b6fb5ee',
    }
  },

  '.active': {
    fontWeight: 700
  }
});

const LogoLink = styled(Link)({
  '@media (max-width: 1199px)': {
    padding: 0,
    '> img': {
      width: 26,
      left: '18px!important',
      objectFit: 'cover',
      objectPosition: 0,
    }
  },
});


// the old react-bootstrap MenuItem doesn't support a as={Link} prop, so let's do this to avoid hard redirect links
function MenuItemLink({ href, shortcut, children, ...props }) {
  const navigate = useNavigate();
  useShortcut(shortcut, () => navigate(href));

  const handleClick = (e) => {
    e.preventDefault();
    navigate(href);
  }

  return <MenuItem href={helpers.ui.href(href)} onClick={handleClick} {...props} title={shortcut}>{children}</MenuItem>;
}


export default function Navbar({
  currentUser,
  markets,
  modes,
  onLogout,
}) {
  const { settings } = store.get();
  const [showMenu, setShowMenu] = useState(false);
  const location = useLocation();
  const isMobile = useMobile('md');

  const operatorLangs = settings?.languages;
  const selectableLangs = intersection(config.adminLangs, operatorLangs);

  const showAnalyticsTab = showAnalytics(settings);

  const tabs = [
    ['dashboard', 'fas fa-sitemap', 'admin', 'valetStorage'],
    ...showAnalyticsTab ? [['analytics', 'fas fa-chart-line', 'admin', undefined, showAnalyticsTab === 1 && { style: { opacity: .65 } }]] : [],
    ['valet-orders', 'fas fa-truck', 'driver', 'valetStorage'],
    ['items', 'fas fa-archive', 'operations', 'valetStorage'],
    ['sites', 'fas fa-building', 'admin', 'selfStorage'],
    ['users', 'fas fa-user', 'admin'],
    ['invoices', 'fas fa-file-invoice-dollar', 'admin'],
  ];

  function toggleMenu() {
    setShowMenu(!showMenu);
  }

  function renderMenuTitle() {
    const { firstName, lastName } = currentUser;

    if (!wurd.editMode) return `${firstName} ${lastName}`;

    // Edit mode; gather all the content keys for the menu
    const editKeys = [
      'nav.help',
      'nav.academy',
      'nav.academyUrl',
      'nav.settings',
      'nav.marketSettings',
      'nav.addons',
      'nav.logout',
      ...config.adminLangs.map((lang) => `languages.${lang}`)
    ];

    return (
      <WurdObject id="common" keys={editKeys}>
        {`${firstName} ${lastName}`}
      </WurdObject>
    );
  }

  function renderMarkets() {
    if (!modes.includes('valetStorage')) return null;

    return markets?.map((market) => (
      <MenuItemLink
        key={market.id}
        href={`/settings/markets/${market.name}`}
      >
        <i className={market.isDefault ? 'fas fa-fw fa-map' : 'far fa-fw fa-map'} />
        <WurdText id="common.nav.marketSettings" vars={{ title: helpers.ui.getLangText(market.title, currentLang) }} style={{ marginLeft: 4 }} />
      </MenuItemLink>
    ));
  }

  function isActive(url) {
    if (location.pathname.startsWith('/' + url)) return true;
    if (url.startsWith('site') && location.pathname.startsWith('/unit')) return true;
    return false;
  }

  return (
    <div id="head-nav" className="navbar navbar-default navbar-fixed-top">
      <div className="container-fluid">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle"
            onClick={toggleMenu}
          >
            <i className="fa fa-bars" />
          </button>

          <LogoLink to="/" className="navbar-brand">
            <img src={`${config.publicUrl}/logo.png`} alt="Storeganise" />
          </LogoLink>

          {isMobile && <div className="pull-right"><QuickAccess modes={modes} /></div>}
        </div>

        <NavbarCollapse className={`navbar-collapse ${!showMenu ? 'collapse' : ''}`}>
          <UlHorizontal className="nav navbar-nav">
            {tabs.map(([name, icon, role, mode, props]) => {
              if (role && !hasRole(role)) return null;
              if (mode && !modes.includes(mode)) return null;

              return (
                <li key={name}>
                  <Link onClick={toggleMenu} to={'/' + name} className={isActive(name) ? 'active' : null} {...props}>
                    <i className={icon} />&nbsp; <WurdText id={cms.id(`nav.${name}`)} />
                  </Link>
                </li>
              );
            })}
          </UlHorizontal>

          {!isMobile && <QuickAccess modes={modes} />}

          <ul className="nav navbar-nav navbar-right">
            <NavDropdown
              id="navbarAccountMenu"
              title={renderMenuTitle(currentUser)}
            >
              <MenuItem
                key="help"
                onClick={() => actions.chat.open()}
              >
                <i className="fa fa-fw fa-question" /> {cms.text('nav.help')}
              </MenuItem>
              <MenuItem
                key="academy"
                href={cms.text('nav.academyUrl')}
                target="_blank"
              >
                <i className="far fa-fw fa-user-graduate" /> {cms.text('nav.academy')}
              </MenuItem>
              <MenuItem key="helpDivider" divider />
              {selectableLangs.map((lang) => (
                <MenuItem
                  key={lang}
                  id={lang}
                  onClick={() => actions.auth.setLanguage(lang)}
                >
                  <i className={`fa-fw far fa-check ${lang === currentLang ? '' : 'invisible'}`} /> {cms.text(`languages.${lang}`)}
                </MenuItem>
              ))}

              {hasRole('manager') && [
                <MenuItem key="settingsDivider" divider />,
                <MenuItemLink
                  key="settingsLink"
                  href="/settings"
                  shortcut="alt+s"
                >
                  <i className="fa fa-fw fa-cog" /> {cms.text('nav.settings')}
                </MenuItemLink>,
                renderMarkets(),
              ]}

              {hasRole('_support') && [
                <MenuItem key="addonsDivider" divider />,
                <MenuItemLink
                  key="addonsLink"
                  href="/addon-configs"
                >
                  <em><i className="far fa-fw fa-cubes" /> {cms.text('nav.addons')}</em>
                </MenuItemLink>,
              ]}

              <MenuItem divider />

              <MenuItem onClick={onLogout}><i className="fa-fw fas fa-sign-out-alt" /> {cms.text('nav.logout')}</MenuItem>
            </NavDropdown>
          </ul>
        </NavbarCollapse>
      </div>
    </div>
  );
}
