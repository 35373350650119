import { ModalForm, Field } from '../../form2';
import CommaList from 'components/form2/comma-list';
import EventsChecklist from './events-checklist';
import { select } from './api-key-form';

export default function WebhookForm(props) {
  return (
    <ModalForm
      wurdSection="business.webhook"
      {...props}
    >
      {getProps => (
        <>
          <Field {...getProps('url')} required />

          <Field {...getProps('events')} className={null}>
            <EventsChecklist />
          </Field>

          <Field {...getProps('alertEmails')} required>
            <CommaList />
          </Field>

          {props.mode === 'update' && <Field type="secret" {...getProps('secret')} readOnly onClick={select} />}
        </>
      )}
    </ModalForm>
  );
}
