import * as config from 'config';
import * as helpers from 'helpers';

import TableListRow from 'components/table-list-row';
import LangText from 'components/lang-text';
import StateLabel from 'components/state-label';
import SiteLoader from 'components/loaders/site';


export default function AddonListInstalledItem({
  cms,
  addonConfig,
  addon,
  hideSite,
}) {
  return (
    <TableListRow
      image={addonConfig?.image || `${config.publicUrl}/addon.png`}
      title={<LangText>{addonConfig?.title}</LangText>}
      subtitle={
        <ul>
          {!hideSite && addon.siteId && (
            <li title="Site">
              <i className="far fa-building" />&nbsp;
              <SiteLoader id={addon.siteId}>
                {(site) => (
                  <LangText>{site.title}</LangText>
                )}
              </SiteLoader>
            </li>
          )}
          {addon.price > 0 && (
            <li title="Price per month">
              <i className="far fa-dollar-sign" /> {helpers.ui.currency(addon.price, { currency: '' })}
            </li>
          )}
        </ul>
      }
      extra1={
        <>
          {addon.state === 'enabled' && (
            <StateLabel
              type="success"
            >
              <cms.Text id="enabled" />
            </StateLabel>
          )}
          {addon.state === 'paused' && (
            <StateLabel
              type="warning"
            >
              <cms.Text id="paused" />
            </StateLabel>
          )}
          {addon.state === 'disabled' && (
            <StateLabel
              type="danger"
            >
              <cms.Text id="disabled" />
            </StateLabel>
          )}
        </>
      }
    />
  )
}
