import { DropdownButton } from 'react-bootstrap';


const wurdIds = [
  
];


export default function AddonConfigDetailMenu({
  modal,
  cms,
  addonConfig,
  refetch,
}) {

  function getMenuItems() {
    const items = [];

    return items;
  }

  const menuItems = getMenuItems();
  if (!menuItems.length) return null;


  return (
    <cms.Object keys={wurdIds.join(',')}>
      <DropdownButton
        title={<i className="fa fa-ellipsis-v" />}
        id="itemMenu"
        bsStyle="link"
        pullRight
        noCaret
      >
        {menuItems}
      </DropdownButton>
    </cms.Object>
  );
}
