import wurd from 'wurd-react';
import { useParams, useNavigate } from 'react-router-dom';

import { useModal, useHistoryListQuery, useListLoader } from 'hooks';
import * as actions from 'actions';

import DetailPage from 'components/detail-page';
import TableList from 'components/table-list';
import AddonConfigListRow from 'components/addons/list/list-row';
import CreateAddonModalForm from 'components/addons/list/forms/create';

const cms = wurd.block('addons');


export default function AddonConfigListController() {
  const { section = 'developer' } = useParams();
  const navigate = useNavigate();
  const modal = useModal();

  const listQuery = useHistoryListQuery();
  const loader = useListLoader('addon-configs', listQuery.params, { maxAge: 0 });

  function createAddon() {
    modal.open(
      <CreateAddonModalForm
        onSubmit={(data) => actions.create('addon-configs', data)}
        onSuccess={(addonConfig) => {
          loader.refetch();
          modal.close();
          navigate(`/addon-configs/${addonConfig.id}`);
        }}
        closeModal={modal.close}
        mode="create"
      />
    );
  }

  
  return (
    <DetailPage
      modal={modal}
      cms={cms}
      userRole="manager"
      titleKey={cms.id('addonConfigs.title')}
      sections={[
        { name: 'developer', icon: 'far fa-code', badge: loader.items.length },
      ]}
      currentSection={section}
      baseUrl={`/addon-configs`}
    >
      <TableList
        cms={cms}
        listQuery={listQuery}
        loader={loader}
        onClickAdd={createAddon}
        getItemLink={(addonConfig) => `/addon-configs/${addonConfig.id}`}
        renderItem={(addonConfig) => {
          // Filter to only tasks that the user is developer of
          // If webtasks are present then they are the developer
          if (!addonConfig.webtasks) return null;

          return (
            <AddonConfigListRow
              addonConfig={addonConfig}
            />
          );
        }}
      />
    </DetailPage>
  );
}
