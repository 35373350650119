import store from '../../store';

/**
 * Creates endpoint for managing documents custom fields
 * 
 * no longer used, now customFields are directly updated from the main actions.model.update endpoint
 */

// /**
//  * @param {'invoices'|'items'|'jobs'|'sites'|'units'|'users'|'valet-orders'} endpoint
//  */
// export default function (endpoint) {
//   return {
//     /**
//      *
//      * @param {String} itemId
//      * @param {Object} data
//      */
//     update(id, data) {
//       return api.put(`/v1/admin/${endpoint}/${id}/customFields`, data)
//         .then(res => {
//           updateCachedData(endpoint, res.data);

//           return res.data;
//         })
//         .catch(errHandler);
//     },
//   }
// }

function toBase64(file) {
  const reader = new FileReader();

  return new Promise((resolve, reject) => {
    reader.onload = () => resolve(window.btoa(reader.result));
    reader.onerror = reject;
    reader.readAsBinaryString(file);
  })
}


export default async function processData(customFields, modelName) {
  const settings = store.get('settings');
  const cfSettings = settings[`${modelName.replace(/-\w/, s => s[1].toUpperCase()).replace(/([^s])s$/, '$1')}CustomFields`];

  const processedEntries = await Promise.all(
    Object.entries(customFields).map(async ([k, v]) => {
      if (v === '' || v === null) {
        return [k, null];
      }

      if (v?.url?.startsWith('blob:') && v.file) { // blob file needing to be uploaded
        const data = await toBase64(v.file);
        return [k, { name: v.file.name, data }];
      }

      const cfSetting = cfSettings.find(o => o.code === k);

      if (cfSetting?.type === 'number' || cfSetting?.type === 'integer') {
        return [k, +v];
      }

      if (cfSetting?.type === 'date-time') {
        return [k, v.padEnd(19, ':00')];
      }

      return [k, v];
    })
  );
  return Object.fromEntries(processedEntries);
}
