import wurd from 'wurd-react'
import DynamicLoader from 'components/dynamic-loader';


// using <i .. /> instead of components/spinner to match billing plugin loaders
const LazyStripePlugin = DynamicLoader(() => import('./stripe'));
const LazyGmopgPlugin = DynamicLoader(() => import('./gmopg'));
const LazyBraintreePlugin = DynamicLoader(() => import('./braintree'));
const LazyCybersourcePlugin = DynamicLoader(() => import('./cybersource'));
const LazyCustomBillingPlugin = DynamicLoader(() => import('./custom-billing'));

const LazyGmopgUserBillingAddPlugin = DynamicLoader(() => import('./gmopg/user-billing-add'));

const cms = wurd.block('billing');


export default function BillingPlugin({ method, ...props }) {
  switch (method) {
    case 'braintree':
      return <LazyBraintreePlugin {...props} />;

    case 'stripe':
      return <LazyStripePlugin {...props} />;

    case 'gmopg':
      return <LazyGmopgPlugin {...props} />;

    case 'cybersource':
      return <LazyCybersourcePlugin {...props} />;

    case 'custom-billing':
      return <LazyCustomBillingPlugin {...props} />;

    default:
      return <div>{cms.text('invalidPayment')} {method}</div>;
  }
}

/**
 * 
 * @param {'gmopg'|'stripe'|'braintree'} props.method
 * @param {User} props.user
 * @param {() => {}} props.onChange
 * @param {() => React.ReactNode} props.children
 */
export function UserBillingAdd({ method, children, user, ...props }) {

  if (user.billing && user.billing.type !== 'invoice') {
    return children(
      <a href={user.billing.adminUrl} target="_blank" rel="noopener noreferrer">{user.billing.id}</a>
    );
  }

  if (user.billing && user.billing.type === 'invoice') { // user.billing just have type:'invoice', no billingAccount to display there
    return children(null);
  }

  switch (method) {
    case 'gmopg':
      return children(<LazyGmopgUserBillingAddPlugin user={user} {...props} />);

    default:
      return children(null);
  }
}
