import * as config from 'config';

import TableListRow from 'components/table-list-row';
import LangText from 'components/lang-text';


export default function AddonConfigListRow({
  addonConfig,
}) {
  return (
    <TableListRow
      image={addonConfig.image || `${config.publicUrl}/addon.png`}
      title={<LangText obj={addonConfig.title} />}
      subtitle={<LangText obj={addonConfig.subtitle} />}
      onClick={() => console.log('clicked')}
    />
  );
}
