import { useHistoryListQuery } from 'hooks';

import UnitDetailsForm from '../unit-detail/forms/unit-details';
import UnitList from '../unit-list';


export default function UnitTypesUnitList({ modal, unitType }) {
  const listQuery = useHistoryListQuery({
    implicitParams: {
      siteId: unitType.siteId,
      typeId: unitType.id,
      include: 'rental,customFields',
    },
  });

  function createUnits() {
    modal.open(
      <UnitDetailsForm
        mode="create"
        multi
        initialValue={{ siteId: unitType.siteId, typeId: unitType.id }}
        onSuccess={() => { modal.close(); listQuery.update({ _t: Date.now() }); }}
        closeModal={modal.close}
      />
    );
  }

  return (
    <UnitList
      listQuery={listQuery}
      modal={modal}
      hideSite
      onClickAdd={createUnits}
    />
  );
}
