import * as actions from 'actions';

import SectionCrudList from 'components/section_crud-list';
import LangText from 'components/lang-text';
import AnnotationForm from './form';


export default function UnitTypeAnnotations({
  modal,
  cms,
  unitType,
  onChange,
}) {
  const initialValue = {
    type: 'text',
    position: 'price_above',
  };


  return (
    <SectionCrudList
      cms={cms}
      items={unitType.annotations}
      Form={AnnotationForm}
      initialValue={initialValue}
      actions={actions.unitTypes.annotations(unitType.id)}
      onChange={onChange}
    >
      {annotation => (
        <div key={annotation.id}>
          <strong><LangText obj={annotation.text} /></strong>
          <div>{annotation.type} · {annotation.position} · {annotation.color}</div>
        </div>
      )}
    </SectionCrudList>
  );
}
