import { useQuery } from 'react-query';
import loadScript from 'load-script2';
import { nonce } from 'config';


export default function Recaptcha() {
  const recaptchaQuery = useQuery('recaptcha', () => loadScript('https://www.google.com/recaptcha/api.js', {
    nonce,
  }));

  const siteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
  if (!siteKey) throw new Error('Missing REACT_APP_RECAPTCHA_SITE_KEY environment variable');

  return (
    <div
      id="recaptcha"
      className="g-recaptcha"
      data-sitekey={siteKey}
      data-size="invisible"
    />
  );
} 